.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* src/Login.css */
body {
  margin: 0;
  /* display: flex; */
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  background-color: #f0f2f5;
  /* Light background for contrast */
}

.login-container {
  max-width: 400px;
  width: 100%;
  /* Full width up to max */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: auto;
  transform: translate(0px, 200px);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.input-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

input:focus {
  border-color: #007BFF;
  outline: none;
}

.error-message {
  color: red;
  text-align: center;
  margin: 10px 0;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #0056b3;
}

.img-thumbnail {
  width: 100px;
  height: 100px;
}



/* .zoomed-image {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
}
.zoomed-image img {
  max-width: 90%;
  max-height: 90%;
  border: 2px solid white;
} */

.img-thumbnail.zoomed {
  transform: scale(1.5);
  transition: transform 0.3s ease;
}
/* .close-btn {
  position: absolute;
  top: 154px;
  right: 20px;
  font-size: 2rem;
  color: red;
  cursor: pointer;
} */
.nav-item{
  padding: 10px 40px;
}

.nav-item.active a {
  color: white !important;
  font-weight: bold;
}


.m-h6{
  font-size: 12px !important;
}
.text-secondary{
  font-size: 12px !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
.vehicle-entry {
  margin-bottom: 20px; /* Adjust as needed */
  padding: 10px; /* Optional: add some padding */
  border: 1px solid #e0e0e0; /* Optional: add a border for visual separation */
  border-radius: 4px; /* Optional: add rounded corners */
}

.zoomed-image {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.image-container {
  position: relative; 
  max-width: 90%;
  max-height: 90%;
}

.zoomed-image img {
  max-width: 100%;
  max-height: 500px;
}

.close-btn {
  position: absolute;
  top: 10px; 
  right: 10px; 
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001; 
  background: rgba(0, 0, 0, 0.5); 
  border-radius: 50%; 
  padding: 5px; 
}
/* Add this CSS to your stylesheet */
.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px; /* Size of the loader */
  height: 50px; /* Size of the loader */
  animation: spin 1s linear infinite;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
